import "../../assets/css/RightPanel.css";
import FetchLicense from "../../actions/FetchLicense";
import { useEffect, useState } from "react";
import {
  Layout,
  Input,
  Divider,
  Spin,
  Modal,
  Tabs,
  Space,
  Table,
  message,
  Badge,
  Card,
  Typography,
  Tag,
  Descriptions,
  Button,
  Popconfirm,
} from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import LicenseActions from "../../actions/LicenseActions";
import TableData from "./TableData";
import useAdminHttp from "../../hooks/useAdminHttp";
import { ADMIN_SERVER_API } from "../../AdminConstants";
import AccountActions from "../../actions/AccountActions";
import NoData from "../../pages/NoData";
import UserActions from "../../actions/UserActions";
import ToolsAction from "../../actions/ToolsAction";
import useAuth from "../../hooks/useAuth";
import { checkIsRunningCampaign, getCampaignStatusFromStatusCode } from "../../../pages/campaign/utils/campaignUtils";
export default function CustomersContainer({ parseJsonIntoAntDTable }) {
  const [licenseData, setLicenseData] = useState(null);
  const [licenseStatus, setLicenseStatus] = useState(null);
  const [stripeSubscriptionData, setStripeSubscriptionData] = useState(null);
  const [chargebeeSubscriptionData, setChargebeeSubscriptionData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [userFormFields, setUserFormFields] = useState({});
  const [numbersFields, setNumbersFields] = useState({});
  const [authFields, setAuthFields] = useState({});
  const [currentTab, setCurrentTab] = useState();
  const [integrationData, setIntegrationData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [phoneNoData, setPhoneNoData] = useState([]);
  const [authenticationData, setAuthenticationData] = useState([]);
  const [userDataFetched, setUserDataFetched] = useState(false);
  const [authenticationDataFetched, setAuthenticationDataFetched] = useState(false);
  const [phoneDataFetched, setPhoneDataFetched] = useState(false);
  const [searchWord, setSearchWord] = useState();
  const [licenseInfoNotifiers, setLicenseInfoNotifiers] = useState();
  const [customerInfoItems, setCustomerInfoItems] = useState([]);
  const [allCRMData, setAllCRMData] = useState();
  const [itemSelectorModalVisible, setItemSelectorModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfItems, setListOfItems] = useState();
  const [filterCampaign, setFilterCampaign] = useState("");
  const [campaignData, setCampaignData] = useState();
  const [campaignTableData, setCampaignTableData] = useState();

  const { fetchData } = useAdminHttp();
  const { Text } = Typography;
  const { customerResData, setCustomerResData, zohoCRMData, setZohoCRMData } = useAuth();
  const isProductionSetup = ADMIN_SERVER_API?.indexOf("oapps.xyz") !== -1;
  console.log("isProductionSetup >>> ", isProductionSetup);

  message.config({
    maxCount: 3,
    top: 50,
  });

  var licenseInfoNotifiersMap = new Map([
    ["basic", "grey"],
    ["gold", "gold"],
    ["silver", "silver"],
    ["unlimited", "magenta"],
    ["free", "white"],
    ["chatgpt", "gainsboro"],
    ["premium", "rosegold"],
  ]);

  var licenseTypeAndTagColorMap = new Map([
    [1, { licenseType: "ACTIVE", tagColor: "green" }],
    [2, { licenseType: "TRIAL", tagColor: "yellow" }],
    [3, { licenseType: "FREE", tagColor: "orange" }],
    [4, { licenseType: "TRIAL", tagColor: "yellow" }],
    [5, { licenseType: "TRIAL", tagColor: "yellow" }],
    [11, { licenseType: "TRIAL ENDED", tagColor: "grey" }],
    [21, { licenseType: "INACTIVE", tagColor: "red" }],
    [22, { licenseType: "INACTIVE", tagColor: "red" }],
    [23, { licenseType: "PAUSED", tagColor: "bisque" }],
    [24, { licenseType: "TRIAL ENDED", tagColor: "grey" }],
    [30, { licenseType: "INACTIVE", tagColor: "red" }],
  ]);

  useEffect(() => {
    if (zohoCRMData) {
      fetchCustomerData(zohoCRMData);
    }
  }, [zohoCRMData]);

  useEffect(() => {
    if (accountData) {
      fetchLicenseData();
      fetchUserData();
    }
  }, [accountData]);

  useEffect(() => {
    if (accountData && !filterCampaign) {
      fetchCampaigns();
    }
  }, [accountData, filterCampaign]);

  useEffect(() => {
    if (campaignData?.length > 0 && filterCampaign) {
      const filteredResult = campaignData.filter((obj) => Object.values(obj).some((value) => value?.toString().includes(filterCampaign)));
      setCampaignTableData(filteredResult);
    } else {
      setCampaignTableData(campaignData);
    }
  }, [campaignData, filterCampaign]);

  useEffect(() => {
    const descriptionItems = document.querySelectorAll(".ant-descriptions-item-content");
    descriptionItems.forEach((item) => {
      item.addEventListener("dblclick", copyHandler);
    });
    function copyHandler(event) {
      navigator.clipboard.writeText(event.currentTarget.textContent);
      message.success("Copied to clipboard");
    }
    return () => {
      descriptionItems.forEach((item) => {
        item.removeEventListener("dblclick", copyHandler);
      });
    };
  }, [customerInfoItems]);

  const getDomainFromPlanName = (data) => {
    let domain = data?.split("_")?.[2]?.replace(/^http(s?):\/\//i, "");
    return domain;
  };

  useEffect(() => {
    try {
      if (customerResData) {
        let accData = clubAccountIntegAndService(customerResData?.account, customerResData?.integration, customerResData?.service);

        if (accData === null) {
          setAccountData(null);
          throw new Error("No Account/Integration data found in our table!");
        }

        setCustomerInfoItems([
          {
            key: 0,
            label: "Name",
            children: zohoCRMData["Full_Name"] === undefined ? "-" : zohoCRMData["Full_Name"],
          },
          {
            key: 1,
            label: "Email",
            children: accData["Email"],
          },
          {
            key: 2,
            label: "Domain",
            children: getDomainFromPlanName(customerResData?.account.planName),
          },
          {
            key: 4,
            label: "Left Service",
            children: accData["Left Service Name"]?.toUpperCase(),
          },
          {
            key: 3,
            label: "Integration ID",
            children: accData["Integ ID"],
          },
          {
            key: 5,
            label: "Right Service",
            children: accData["Right Service Name"]?.toUpperCase(),
          },
          {
            key: 6,
            label: "Osync ID",
            children: accData["Osync ID"],
          },
        ]);
        if (customerResData?.omni) {
          checkAndSetLicenseType(customerResData?.omni);
        } else {
          setLicenseInfoNotifiers(null);
          setLicenseData(null);
          message.warning("No Omni License data found.");
        }
        setAccountData(accData);
        setIntegrationData(customerResData?.integration);
        setServiceData(customerResData?.service);
        getFields("user");
        getFields("number");
        getFields("authentication");
        setLicenseData(customerResData?.omni);
      }
    } catch (error) {
      setAccountData(null);
      setLicenseData(null);
      console.error("Error:", error.message);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [customerResData]);

  const fetchCustomerData = (currentData) => {
    setLoading(true);
    console.log("Incoming data to fetchCustomerData >>> ", currentData);
    fetchData("customer?osyncId=" + currentData?.osyncId, "get", null)
      .then((res) => {
        if (res?.status === 200 && res?.data) {
          console.log("res from /customer :::::" + JSON.stringify(res.data));

          setCustomerResData(res?.data);
        } else {
          throw new Error("No data found !");
        }
      })
      .catch((err) => {
        setAccountData(null);
        setLicenseData(null);
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const leftServiceName = accountData && accountData["Left Service Name"]?.toLowerCase()?.replace(/[-_\s]/g, "");
  const rightServiceName = accountData && accountData["Right Service Name"]?.toLowerCase()?.replace(/[-_\s]/g, "");
  const serviceMap = new Map([["zohocrm_ringcentral", "stripe"]]);
  const concatenatedServiceName = leftServiceName + "_" + rightServiceName;

  const fetchLicenseData = () => {
    if (serviceMap.get(concatenatedServiceName) === "stripe") {
      fetchData(
        "stripe/customer?osyncId=" +
          accountData["Osync ID"] +
          "&leftServiceId=" +
          accountData["Left Service ID"] +
          "&rightServiceId=" +
          accountData["Right Service ID"],
        "get",
        null
      )
        .then((res) => {
          try {
            if (res?.status === 200 && res?.data) {
              console.log("stripesub", JSON.stringify(res?.data));
              setLicenseStatus(res?.data?.subscription?.status);
              setStripeSubscriptionData(res?.data);
              setChargebeeSubscriptionData(null);
            } else {
              setLicenseStatus(null);
              setStripeSubscriptionData(null);
              setChargebeeSubscriptionData(null);
              message.warning("No Subscription Data Found");
            }
          } catch (error) {
            console.error("Error processing subscription data:", error);
            message.error("Error occurred while processing subscription data");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          message.error("An error occurred while fetching data.");
        });
    } else {
      fetchData("chargebee/customer?osyncId=" + customerResData?.account?.osyncId, "get", null)
        .then((res) => {
          try {
            if (res?.status === 200 && res?.data) {
              console.log("chargebeesub>>>>>>", JSON.stringify(res?.data));
              setChargebeeSubscriptionData(res?.data);
              setStripeSubscriptionData(null);
              setLicenseStatus(res?.data?.subscription?.subscription?.status);
            } else {
              setLicenseStatus(null);
              setChargebeeSubscriptionData(null);
              setStripeSubscriptionData(null);

              message.warning("No Subscription Data Found!");
            }
          } catch (error) {
            console.error("Error processing subscription data:", error);
            message.error("An error occurred while processing subscription data");
          }
        })
        .catch((error) => {
          console.error("Error fetching subscription data:", error);
          message.error("Failed to fetch subscription data.");
        });
    }
  };
  const checkAndSetLicenseType = (data) => {
    var tempTypeAndTag = licenseTypeAndTagColorMap.get(data.licenseStatus);
    var tempLicenseNotifier = {
      licensePlanName: data.licensePlanName,
      ribbonColour: licenseInfoNotifiersMap.get(data?.licensePlanName?.toLowerCase()),
      licenseType: tempTypeAndTag.licenseType,
      tagColor: tempTypeAndTag.tagColor,
    };
    console.log("tempLicenseNotifier", tempLicenseNotifier);
    setLicenseInfoNotifiers(tempLicenseNotifier);
  };

  const clubAccountIntegAndService = (accData, integData, servData) => {
    let clubbedAccountData;
    if (accData) {
      if (accData?.zcrmMigratedNewOsyncId) {
        setSearchWord(accData?.zcrmMigratedNewOsyncId);
        searchHandler(accData?.zcrmMigratedNewOsyncId);
      }
      let leftServiceName = null;
      let rightServiceName = null;
      if (!integData) {
        message.warning("No integration found");
        integData = {
          leftServiceId: null,
          rightServiceId: null,
          integId: null,
        };
      } else {
        servData.forEach((ele) => {
          if (ele?.serviceId === integData[0]?.leftServiceId) {
            leftServiceName = ele?.displayName;
          }
        });
        servData.forEach((ele) => {
          if (ele?.serviceId === integData[0]?.rightServiceId) {
            rightServiceName = ele?.displayName;
          }
        });
      }

      clubbedAccountData = {
        "Left Service ID": integData.length > 0 ? integData[0].leftServiceId : integData.leftServiceId,
        "Right Service ID": integData.length > 0 ? integData[0].rightServiceId : integData.rightServiceId,
        "Remote Identifier": accData.remoteIdentifier,
        "Channel Type": accData.accountType,
        Inbox: accData.isInboxEnabled,
        "Left Service Name": leftServiceName,
        "Right Service Name": rightServiceName,
        "Created Time": accData.createdTime,
        "Integ ID": integData.length > 0 ? integData[0].integId : integData.integId,
        "Osync ID": accData?.osyncId,
        Email: accData.email,
        "Subscription Start": zohoCRMData["Subscription_Start"] ? zohoCRMData["Subscription_Start"] : "-",
        "Subscription Update": zohoCRMData["Subscription_Update"] ? zohoCRMData["Subscription_Update"] : "-",
        zcrmMigratedNewOsyncId: accData?.zcrmMigratedNewOsyncId,
      };
    } else {
      return null;
    }
    console.log("clubbed data ::: ", clubbedAccountData);
    return clubbedAccountData;
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "OsyncId",
      dataIndex: "osyncId",
      key: "osyncId",
    },
  ];

  const searchHandler = (osyncId) => {
    if (loading) return;
    setLoading(true);
    let searchItem = searchWord;
    if (osyncId && typeof osyncId === "string") searchItem = osyncId;
    fetchData("search?searchWord=" + searchItem, "get", null)
      .then((res) => {
        console.log("enteredd", res);
        if (res?.status === 200 && res?.data) {
          console.log("res from /search ::: ", JSON.stringify(res.data));
          const searchData = res.data.data || [res.data];
          handleSearchFromZohoSearch(searchData);
          setCurrentTab("license");
          setPhoneNoData([]);
          setUserData([]);
          setIntegrationData([]);
          setServiceData([]);
          setAuthenticationData([]);
          setAuthenticationDataFetched(false);
          setUserDataFetched(false);
          setPhoneDataFetched(false);
        } else if (res?.status === 403) {
          throw new Error("Something wrong happened");
        } else if (res?.status === 200) {
          throw new Error("No response from Zoho CRM.");
        }
      })
      .catch((err) => {
        message.error(err.message);
        setLicenseData(null);
        setAccountData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCurrentTab = (key) => {
    setCurrentTab(key);
    if (key === "numbers" && !phoneDataFetched) {
      if (accountData["Osync ID"] !== null && integrationData?.length > 0 && serviceData?.length > 0) {
        integrationData.forEach((integration) => {
          serviceData.forEach((service) => {
            if (integration?.rightServiceId === service?.serviceId) {
              fetchPhoneData(accountData["Osync ID"], integration?.integId);
            }
          });
        });
        setPhoneDataFetched(true);
      }
    } else if (key === "authentications" && !authenticationDataFetched) {
      if (accountData["Osync ID"]) {
        fetchCustomerAuthData(accountData["Osync ID"]);
        setAuthenticationDataFetched(true);
      }
    }
  };

  const getFields = (className) => {
    try {
      if (className) {
        fetchData(`${className}/schema`, "get", null).then((res) => {
          if (res?.status === 200 && res?.data) {
            console.log("Fields api ;;;;", res?.data);
            if (className === "user") {
              setUserFormFields(res?.data);
            } else if (className === "authentication") {
              setAuthFields(res?.data);
            } else if (className === "number") {
              setNumbersFields(res?.data);
            }
          }
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const fetchUserData = () => {
    setLoading(true);
    if (accountData) {
      fetchData("customer/users?osyncId=" + accountData["Osync ID"], "get", null)
        .then((res) => {
          try {
            if (res?.status === 200 && res?.data.length > 0) {
              console.log("the user data is :::", res?.data);
              setUserDataFetched(true);
              setUserData(res?.data);
            } else if (res.status === 200 && res?.data.length <= 0) {
              message.error("There is no user");
            }
          } catch (error) {
            console.error("Error fetching Data:", error);
          }
        })
        .catch((error) => {
          message.error(error.message);
          setUserData(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const fetchCampaigns = () => {
    if (accountData) {
      let targetUrl = "customer/campaigns?osyncId=" + accountData["Osync ID"];
      if (filterCampaign) {
        targetUrl += "&campaignId=" + filterCampaign;
      }
      fetchData(targetUrl, "get", null)
        .then((res) => {
          try {
            if (res?.status === 200 && res?.data?.length > 0) {
              setCampaignData(res?.data);
            } else {
              setCampaignData();
            }
          } catch (error) {
            console.error("err  ", error);
          }
        })
        .catch((error) => {})
        .finally(() => {});
    }
  };
  const fetchPhoneData = (osyncId, integId) => {
    setLoading(true);
    if (osyncId && integId) {
      fetchData("customer/numbers?osyncId=" + osyncId, "get", null)
        .then((res) => {
          if (res?.status === 200 && res?.data) {
            console.log("Phone No data ::::", res?.data);
            setPhoneNoData((prevData) => [...prevData, ...res?.data]);
          }
        })
        .catch((error) => {
          message.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchCustomerAuthData = (osyncId) => {
    setLoading(true);
    if (osyncId) {
      fetchData("customer/authentications?osyncId=" + osyncId, "get", null)
        .then((res) => {
          if (res?.status === 200 && res?.data) {
            setAuthenticationData(res?.data);
          } else if (res?.status === 200 && res.data.length < 1) {
            setAuthenticationData([]);
          }
        })
        .catch((error) => {
          message.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDelete = (record, tab) => {
    try {
      if (tab === "user") {
        const { osyncUserId, osyncId } = record;
        if (osyncUserId && osyncId) {
          console.log("handle delete user", osyncUserId, osyncId);
          fetchData(`customer/user/${osyncUserId}?osyncId=` + osyncId, "delete", null)
            .then((res) => {
              if (res?.status === 200 && res?.data) {
                const newUser = userData.filter((items) => items.osyncUserId !== osyncUserId);
                setUserData(newUser);
              }
            })
            .catch((error) => {
              message.error(error.message);
            });
        }
      } else if (tab === "number") {
        const { osyncId_integId, phoneNumber } = record;
        const integId = osyncId_integId?.split("_")[1];
        if (integId && phoneNumber && accountData["Osync ID"]) {
          console.log("Numbers delete", integId, phoneNumber, accountData["Osync ID"]);
          fetchData(`customer/number/${phoneNumber}?osyncId=` + accountData["Osync ID"] + "&integId=" + integId, "delete", null)
            .then((res) => {
              if (res?.status === 200 && res?.data) {
                const newPhoneNumbers = phoneNoData.filter((items) => items.phoneNumber !== phoneNumber);
                setPhoneNoData(newPhoneNumbers);
              }
            })
            .catch((error) => {
              message.error(error.message);
            });
        }
      } else if (tab === "authentication") {
        if (accountData["Osync ID"]) {
          const data = Object?.fromEntries(Object?.entries(record)?.filter(([key, value]) => key !== "key"));
          const payload = JSON.stringify(data);
          console.log("this is payload service", payload);
          fetchData("customer/authentication?osyncId=" + accountData["Osync ID"], "delete", payload)
            .then((res) => {
              if (res?.status === 200 && res?.data) {
                const newAuthData = authenticationData.filter(
                  (items) => items.osyncId_serviceId !== record.osyncId_serviceId && items.osyncId_integId !== record.osyncId_integId
                );
                setAuthenticationData(newAuthData);
              }
            })
            .catch((error) => {
              message.error(error.message);
            });
        }
      } else if (tab === "campaign") {
        const { campaignId, osyncId } = record;
        if (campaignId && osyncId) {
          console.log("stop  this campign", osyncId, campaignId);
          fetchData(`customer/campaign/${campaignId}?osyncId=` + osyncId, "PUT", null)
            .then((res) => {
              if (res?.status === 200 && res?.data) {
                const updatedCampaignData = campaignData.map((item) => (item.campaignId === campaignId ? { ...item, ...res?.data } : item));
                setCampaignData(updatedCampaignData);
              }
            })
            .catch((error) => {
              message.error(error.message);
            });
        }
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const tableDataColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const integrationColumns = [
    {
      title: "LeftServiceName",
      dataIndex: "leftServiceName",
      key: "leftServiceName",
    },
    {
      title: "LeftServiceId",
      dataIndex: "leftServiceId",
      key: "leftServiceId",
    },
    {
      title: "RightServiceId",
      dataIndex: "rightServiceId",
      key: "rightServiceId",
    },
    {
      title: "rightServiceName",
      dataIndex: "rightServiceName",
      key: "rightServiceName",
    },
  ];
  const campaignColumns = [
    {
      title: "CampaignId",
      dataIndex: "campaignId",
      key: "campaignId",
    },
    {
      title: "Status",
      dataIndex: "campaignStatus",
      key: "campaignStatus",
      render: (data) => getCampaignStatusFromStatusCode(data)?.toUpperCase(),
    },
    {
      title: "Scheduled",
      dataIndex: "scheduledCampaign",
      key: "scheduledCampaign",
    },
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
    },
    {
      title: "integId",
      dataIndex: "integId",
      key: "integId",
    },
  ];
  const userColumns = Object.keys(userFormFields)
    ?.filter((column) => column !== "osyncId" && column !== "shardId" && column !== "adminUser")
    ?.map((column) => {
      return {
        title: column.charAt(0).toUpperCase() + column.slice(1),
        dataIndex: column,
        key: column,
      };
    });

  const authenticationsColumns = Object?.keys(authFields)
    ?.filter((column) => column !== "accessToken" && column !== "refreshToken" && column !== "securityContext")
    ?.map((column) => ({
      title: column.charAt(0).toUpperCase() + column.slice(1),
      dataIndex: column,
      key: column,
      width: 200,
    }));
  const numbersColumns = Object?.keys(numbersFields)?.map((column) => ({
    title: column.charAt(0).toUpperCase() + column.slice(1),
    dataIndex: column,
    key: column,
    width: 200,
  }));
  const columnWithDelete = (columnsData, handleDelete, dataType, data, Delete) => {
    const columns = columnsData.map((column) => ({
      title: column.title,
      dataIndex: column.dataIndex,
      key: column.key,
      render: column?.render,
    }));

    const filteredColumns = [
      {
        title: "",
        dataIndex: "deleteButton",
        key: "deleteButton",
        render: (text, record) => (
          <Popconfirm
            title={`${Delete} ${dataType}`}
            description={`Are you sure you want to ${Delete} this ${dataType}?`}
            onConfirm={() => handleDelete(record, dataType)}
            okText="Yes"
            cancelText="No"
            className={`${record?.campaignStatus && !checkIsRunningCampaign(record?.campaignStatus) && "d-none"}`}
          >
            {dataType === "user" && data?.length <= 1 ? (
              <Button danger disabled>
                {Delete} {dataType}
              </Button>
            ) : (
              <Button danger>
                {Delete} {dataType}
              </Button>
            )}
          </Popconfirm>
        ),
      },
      ...columns.filter((column) =>
        data?.some((item) => item[column.dataIndex] !== null && item[column.dataIndex] !== undefined && item[column.dataIndex] !== "")
      ),
    ];

    return filteredColumns;
  };
  let integrationTabData = [];
  const addServiceNamesToIds = (integrations, services) => {
    return integrations?.map((integration) => {
      const { leftServiceId, rightServiceId } = integration;
      const leftService = services?.find((service) => service.serviceId === leftServiceId);
      const rightService = services?.find((service) => service.serviceId === rightServiceId);
      return {
        ...integration,
        leftServiceName: leftService ? leftService.name : null,
        rightServiceName: rightService ? rightService.name : null,
      };
    });
  };

  if (integrationData && customerResData?.service) {
    integrationTabData = addServiceNamesToIds(integrationData, customerResData?.service);
  }

  const excludedFields = [
    "osyncId",
    "shardId",
    "adminUser",
    "zcrmMigrationTag",
    "phone",
    "adminUser",
    "firstName",
    "avatar",
    "osyncUserId",
    "timeZone",
    "lastName",
    "country",
    "fullName",
  ];
  const filteredUserFormFields = Object.keys(userFormFields)
    .filter((field) => !excludedFields.includes(field))
    .reduce((obj, key) => {
      obj[key] = userFormFields[key];
      return obj;
    }, {});

  const customerTabItems = [
    {
      label: "License",
      key: "license",
      children: (
        <Space className="w-100" direction="vertical">
          <>
            <Space className="w-100" direction="horizontal">
              <LicenseActions fetchCustomerData={fetchCustomerData} licenseData={licenseData} />
              <FetchLicense
                accountData={accountData}
                status={licenseStatus}
                stripeSubscriptionData={stripeSubscriptionData}
                chargebeeSubscriptionData={chargebeeSubscriptionData}
              />
            </Space>
            <TableData customersData={licenseData} columns={tableDataColumns} rowWise={true} />
          </>
        </Space>
      ),
    },
    {
      label: "Account",
      key: "account",
      children: (
        <Space className="w-100" direction="vertical">
          <AccountActions fetchCustomerData={fetchCustomerData} accountData={accountData} />
          <TableData customersData={accountData} columns={tableDataColumns} rowWise={true} />
        </Space>
      ),
    },
    {
      label: "Tools",
      key: "tools",
      id: "tools",
      children: <ToolsAction userData={userData} />,
    },
    {
      label: "Integrations",
      key: "integrations",
      children: <TableData customersData={integrationTabData} columns={integrationColumns} rowWise={false} />,
    },
    {
      label: "Campaigns",
      key: "campaigns",
      children: (
        <Space direction="vertical" className="w-100">
          <Input
            value={filterCampaign}
            onChange={(e) => {
              setFilterCampaign(e.target.value);
            }}
            placeholder="Search or filter Campaigns"
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                fetchCampaigns();
              }
            }}
          />
          <TableData
            customersData={campaignTableData}
            columns={columnWithDelete(campaignColumns, handleDelete, "campaign", campaignTableData, "Stop")}
            rowWise={false}
          />
        </Space>
      ),
    },
    {
      label: "User",
      key: "user",
      children: (
        <Space className="w-100" direction="vertical">
          <UserActions osyncIds={accountData} fetchUserData={fetchUserData} formFields={userFormFields} />
          <TableData
            customersData={userData}
            columns={columnWithDelete(userColumns, handleDelete, "user", userData, "Delete")}
            rowWise={false}
          />
        </Space>
      ),
    },
    {
      label: "Numbers",
      key: "numbers",
      children: (
        <Space className="w-100" direction="vertical">
          <TableData
            customersData={phoneNoData}
            columns={columnWithDelete(numbersColumns, handleDelete, "number", phoneNoData, "Delete")}
            rowWise={false}
          />
        </Space>
      ),
    },
    {
      label: "Authentications",
      key: "authentications",
      children: (
        <Space className="w-100" direction="vertical">
          <TableData
            customersData={authenticationData}
            columns={columnWithDelete(authenticationsColumns, handleDelete, "authentication", authenticationData, "Delete")}
            rowWise={false}
          />
        </Space>
      ),
    },
    {
      label: <>{licenseInfoNotifiers ? <Tag color={licenseInfoNotifiers.tagColor}>{licenseInfoNotifiers.licenseType}</Tag> : <></>}</>,
      key: "licenseType",
      disabled: true,
    },
  ];

  const handleSearchFromZohoSearch = (data) => {
    if (data && data.length > 0) {
      if (data.length > 1) {
        setAllCRMData(data);
        var temp = [];
        data.forEach((item) => {
          var subTemp = {
            osyncId: item.OsyncId,
            email: item.Email,
          };
          temp.push(subTemp);
        });
        setListOfItems(parseJsonIntoAntDTable(temp, false));
        setItemSelectorModalVisible(true);
      } else {
        console.log("else in handleSearchFromZohoSearch");
        const currentData = data[0];
        if (currentData.OsyncId && isProductionSetup) {
          currentData["osyncId"] = currentData["OsyncId"];
        }
        setZohoCRMData(currentData);
      }
    }
  };

  const searchInputChangeHandler = (e) => {
    setSearchWord(e.currentTarget.value);
  };

  const antIcon = <LoadingOutlined style={{ color: "blueviolet", fontSize: 40 }} spin />;

  const handleRowClick = (record) => {
    const currentCustomerCRMData = allCRMData.find((ele) => ele["OsyncId"] === record.osyncId);
    if (currentCustomerCRMData.OsyncId && isProductionSetup) {
      currentCustomerCRMData["osyncId"] = currentCustomerCRMData["OsyncId"];
    }
    console.log("current customer crmData ::: ", currentCustomerCRMData);
    setZohoCRMData(currentCustomerCRMData);
    setItemSelectorModalVisible(false);
  };

  return (
    <>
      {listOfItems ? (
        <Modal
          style={{ minWidth: "70%" }}
          open={itemSelectorModalVisible}
          title="Customers"
          maskClosable={false}
          onOk={null}
          footer={null}
          onCancel={() => setItemSelectorModalVisible(false)}
        >
          <Table
            className="mt-4"
            scroll={{ y: 400 }}
            pagination={false}
            dataSource={listOfItems}
            columns={columns}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
          />
        </Modal>
      ) : (
        <></>
      )}

      <Layout className="ms-5">
        <div className="row w-100 mt-3 justify-content-center">
          <div className="col-5">
            <div className="row d-flex searchBar">
              <div className="col-10">
                <Input
                  data-cy="customerSearchBar"
                  onPressEnter={searchHandler}
                  value={searchWord}
                  bordered={false}
                  onChange={searchInputChangeHandler}
                  placeholder="Enter Osync Id / Email / Domain / Remote Identifier"
                />
              </div>
              <div className="col-2">
                <SearchOutlined onClick={searchHandler} className="searchIcon" />
              </div>
            </div>
            <Divider plain style={{ padding: "0", margin: "0" }} />
          </div>
        </div>
        {accountData ? (
          <div className="row w-100 justify-content-center mt-2">
            <div className="summaryTableWrapper">
              <Badge.Ribbon
                text={<Text>{licenseInfoNotifiers ? licenseInfoNotifiers.licensePlanName : ""}</Text>}
                color={licenseInfoNotifiers ? licenseInfoNotifiers.ribbonColour : "transparent"}
              >
                <Card color="#d9d9d9" size="small">
                  <Descriptions colon={false} size="small" layout="vertical" items={customerInfoItems} />
                </Card>
              </Badge.Ribbon>
            </div>
          </div>
        ) : (
          <></>
        )}

        {loading ? (
          <div style={{ background: "rgb(250,250,250,.2)", height: "100vh" }}>
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
              <Spin indicator={antIcon} />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row w-100 justify-content-center customerContainer">
          <div className="col-11">
            {accountData ? <Tabs items={customerTabItems} onChange={handleCurrentTab} activeKey={currentTab} /> : <NoData />}
          </div>
        </div>
      </Layout>
    </>
  );
}
